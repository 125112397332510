<template>
  <router-view />
</template>

<script>
export default {
  name: 'LayoutFull',
  components: {},
};
</script>
